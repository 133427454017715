import React from 'react'
import './roadmap.css'
import roadmap from '../../images/roadmap.png'

function RoadmapSection() {
	return (
		<div className='roadmapWrapper' id='roadmap'>
			<div className='roadmapTitle'>Roadmap</div>
			<img className='roadmapImage' src={roadmap} alt='' />
		</div>
	)
}

export default RoadmapSection