import React from 'react'
import {Link as Link1}   from 'react-scroll'
import logo from '../../images/logo192.png'
import './navbar.css'
import ReorderIcon from '@mui/icons-material/Reorder'

function Navbar(props) {
	return (
		<div className="navbar">
			<div className="leftSide" id="close">
				<img className='logoSize' src={logo} alt="" />
				<div className='navTitle'>pepebonk</div>
			</div>
			<div className="rightSide">
				<Link1 className="link" to="tokenInfo">Token Info</Link1>
				<Link1 className="link" to="tokenomics">Tokenomics</Link1>
				<Link1 className="link" to="audit">Audit</Link1>
				<Link1 className="link" to="whitepaper">Whitepaper</Link1>
				<Link1 className="link" to="roadmap">Roadmap</Link1>
				<button onClick={props.toggle}>
					<ReorderIcon />
				</button>
			</div>
		</div>
	)
}

export default Navbar