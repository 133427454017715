import React from 'react'
import './tokenInfo.css'
import uni from '../../images/uni.png'
import etherscan from '../../images/etherscan.png'
import telegram from '../../images/telegram.png'
import twitter from '../../images/twitter.png'

function TokenInfoSection() {
	return (
		<div className='tokenInfoContainer' id='tokenInfo'>
			<div className='tokenomicsTitle'>Token Info</div>
			<div className='contractText'>Contract Address: 0x28eeAc83B9c843ef2fC6C78BCd860cAb46aA88d0</div>
			<div className='tokenInfoWrapper' >
				
				<a className='infoPiece' href='https://app.uniswap.org/swap?&chain=mainnet&use=v2&outputCurrency=0x28eeac83b9c843ef2fc6c78bcd860cab46aa88d0' target='_blank' rel="noreferrer">
					<img className='infoImage' src={uni} alt=''/>
					Buy Coins
				</a>
				<a className='infoPiece' href='https://etherscan.io/token/0x28eeAc83B9c843ef2fC6C78BCd860cAb46aA88d0' target='_blank' rel="noreferrer">
					<img className='infoImage' src={etherscan} alt=''/>
					Check Contract
				</a>
				<a className='infoPiece' href='https://t.me/pepebonktg' target='_blank' rel="noreferrer">
					<img className='infoImage' src={telegram} alt=''/>
					Telegram
				</a>
				<a className='infoPiece' href='https://twitter.com/realPepeBonk' target='_blank' rel="noreferrer">
					<img className='infoImage' src={twitter} alt=''/>
					Twitter
				</a>
			</div>
		</div>
	)
}

export default TokenInfoSection