import React from 'react'
import {
	SidebarContainer,
	Icon,
	CloseIcon,
	SidebarWrapper,
	SidebarMenu,
} from './SidebarElements';
import {Link as Link1}   from 'react-scroll'



const Hamburger = (props) => {

	return(
		<SidebarContainer isOpen={props.isOpen} onClick={props.toggle}>
			<Icon onClick={props.toggle}>
				<CloseIcon />
			</Icon>
			<SidebarWrapper>
				<SidebarMenu>
					<Link1 className="sl" to="tokenInfo" onClick={props.toggle}>Token Info</Link1>
					<Link1 className="sl" to="tokenomics" onClick={props.toggle}>Tokenomics</Link1>
					<Link1 className="sl" to="audit" onClick={props.toggle}>Audit</Link1>
					<Link1 className="sl" to="whitepaper" onClick={props.toggle}>Whitepaper</Link1>
					<Link1 className="sl" to="roadmap" onClick={props.toggle}>Roadmap</Link1>

				</SidebarMenu>
			</SidebarWrapper>
		</SidebarContainer>
	)
}

export default Hamburger