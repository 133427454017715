import React from 'react'
import './footer.css'

function FooterSection() {
	return (
		<div className='footerWrapper'>
			<div className='footerText'>PepeBonk is not a financial instrument, it is a simple meme. Invest at your own risk. PepeBonk is not affiliated with any of the following individuals or entities: DogeBonk, Coinbase, SEC, McDonald's, Klaus Schwab, Easton, AIPAC, CoinGecko, Vitalik Butherin, or Jiffy Lube.</div>
		</div>
	)
}

export default FooterSection