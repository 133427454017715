import React, { useState, useEffect } from 'react'
import './bonk.css'
import pic1 from '../../images/partA2.png'
import pic2 from '../../images/partB.png'
import pic2b from '../../images/partB2.png'
import pic3 from '../../images/partC2.png'
import pic4 from '../../images/partD4.png'

function BonkSection() {
	const [pepe, setPepe] = useState(pic3)
	let bonkSound = new Audio('/bonk2.mp3')
	// var armClassName = 'bonkArm2'
	let armClassName2
	// let pepe = pic3
	// useEffect(() => {
	// 	let armClassName2 = document.getElementById('wojakArm') ? document.getElementById('wojakArm') : '';
	// 	armClassName2.className = 'bonkArm2'
	// },[])
	

	// console.log(armClassName)
	function timeout(delay) {return new Promise( res => setTimeout(res, delay))}

	async function bonk() {
		let armClassName2 = document.getElementById('wojakArm')
		// console.log(armClassName)
		// var armClassName = 'clicked2'
		armClassName2.className = 'clicked2'
		
		bonkSound.play()
		
		await timeout(350)
		changePepe()
		armClassName2.className = 'bonkArm2'

		// armClassName2.className = 'bonkArm2'
		// var armClassName = 'bonkArm2'
	}
	async function changePepe() {
		setPepe(pic4)
		await timeout(200)
		setPepe(pic3)
	}

	return (
		<div className='bonkSection'>
			<div className='bonkWrapper' >
				<div className='bonkContainer' >
					<img id='wojakArm'className='bonkArm2' src={pic2b} alt=''/>
					<img className='wojakBody' src={pic1} alt=''/>
					<img className='bonkPepe' src={pepe} alt=''/>

				</div>
			</div>
			<div className='headerContainer'>
				<div className='headerText' onClick={bonk}>Click to Bonk!</div>
			</div>
		</div>
	)
}

export default BonkSection