import React from 'react'
import './whitepaper.css'
import paper from '../../images/paper.png'

function WhitepaperSection() {
	return (
		<div className='whitepaperWrapper' id='whitepaper'>
			<div className='whitepaperTitle'>Whitepaper</div>
			<img className='whitepaperImage' src={paper} alt='' />
		</div>
	)
}

export default WhitepaperSection