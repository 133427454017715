import React from 'react'
import './tokenomics.css'

function TokenomincsSection() {
	return (
		<div className='tokenomicsWrapper' id='tokenomics'>
			<div className='tokenomicsTitle'>Tokenomics</div>
			<div className='tokenomicsInfoWrapper'>
				<div className='tokenomicsInfoPiece'>
					<div className='tokenomicsInfoHeader'>Total Supply:</div>
					<div className='tokenomicsInfoText'>10 million</div>
				</div>
				<div className='tokenomicsInfoPiece'>
					<div className='tokenomicsInfoHeader'>Marketing Tax:</div>
					<div className='tokenomicsInfoText'>1%</div>
				</div>
				<div className='tokenomicsInfoPiece'>
					<div className='tokenomicsInfoHeader'>Max Transaction:</div>
					<div className='tokenomicsInfoText'>250k</div>
				</div>
				<div className='tokenomicsInfoPiece'>
					<div className='tokenomicsInfoHeader'>LP Status:</div>
					<div className='lockLinkWrapper'><a className='locklink' href='https://app.uncx.network/amm/uni-v2/pair/0x57919548feA4b7e0B2321fe829855ca33a2F119A' target='_blank' rel="noreferrer">Locked</a></div>
				</div>
			</div>
		</div>
	)
}

export default TokenomincsSection