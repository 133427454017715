import React from 'react'
import './audit.css'
import jeet from '../../images/jeet.png'
import play from '../../images/play.png'

function AuditSection() {
	let ranjeesh = new Audio('/ranjeesh.mp3')
	return (
		<div className='auditWrapper' id='audit'>
			<div className='auditTitle'>World Class Audit</div>
			<div className='auditInfoContainer'>
				<div className='auditLeftSide'>
					<div className='auditQuote'>"The PepeBonk coin has been audited and it is very good sir." - Ranjeesh</div>
					<div className='testimonialWrapper'>
						<div className='testimonialText'>Click to hear Ranjeesh's testimonial!</div>
						<img className='playButton' src={play} alt='' onClick={() => ranjeesh.play()}/>
					</div>
				</div>
				<div className='auditRightSide'>
					<img className='auditImage' src={jeet} alt='' />
					<div className='auditImageCaption'>
						<div className='captionName'>Ranjeesh Balkeen Mujavontigonjo, Lead Auditor</div>
						<div className='captionCompany'>India Active Software, INC</div>
						<div className='captionCoAddr'>4373+R2 Nagpur, Maharashtra</div>
						<div className='captionNNumber'>+91 712 664 5299</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default AuditSection