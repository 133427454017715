import React, { useState} from 'react';
import './App.css';
import Navbar from './components/Navbar'
import BonkSection from './components/BonkSection'
import TokenInfoSection from './components/TokenInfoSection'
import AuditSection from './components/AuditSection'
import WhitepaperSection from './components/WhitepaperSection'
import RoadmapSection from './components/RoadmapSection'
import TokenomicsSection from './components/TokenomicsSection'
import FooterSection from './components/FooterSection'
import Hamburger from './components/HamburgerMain'
import pic4 from './images/partD4.png'

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="App">
      <Navbar toggle={toggle}/>
      <Hamburger isOpen={isOpen} toggle={toggle}/>
      <BonkSection />
      <TokenInfoSection />
      <TokenomicsSection />
      <AuditSection />
      <WhitepaperSection />
      <RoadmapSection />
      <FooterSection />
      <img className='bonkLoader' src={pic4} alt='' />
    </div>
  );
}

export default App;
